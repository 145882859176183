<template>
  <div id="form-template">
    <h5 v-if="title" class="mt-1" style="margin: 0">{{ title }}</h5>

    <hr />

    <form @submit.prevent="$emit('submit', formdata)">
      <b-form-fieldset :disabled="loadingState">
        <div v-for="field in form" :key="field.name">
          <components
            v-bind="field"
            v-model="formdata[field.name]"
            :key="updateComponent"
            :is="field.component"
            :errors="errors"
            @updateFieldValue="updateFieldValue"
          />
        </div>
      </b-form-fieldset>

      <div class="buttons text-center">
        <button
          type="submit"
          class="btn btn-pompe-primary btn-sm mr-2"
          :disabled="loadingState"
        >
          <span v-if="!loadingState">{{ submitText }}</span>
          <span v-else>
            <b-spinner small label="Small Spinner"></b-spinner>
          </span>
        </button>
        <button
          type="button"
          class="btn btn-secondary btn-sm"
          :disabled="loadingState"
          @click="cancelDialog"
        >
          {{ cancelText }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "FormTemplate",
  props: {
    // page title
    title: {
      type: String,
      default: "",
    },
    // fields that will be displayed
    form: {
      type: Array,
      default: () => [],
    },
    // loading state
    formLoading: {
      type: Boolean,
      default: false,
    },
    // class java
    javaClass: {
      type: String,
      default: null,
    },
    submitText: {
      type: String,
      default: "Submit",
    },
    cancelText: {
      type: String,
      default: "Cancel",
    },
    formEdit: {
      type: Object,
      default: () => {},
    },
    backButtonDestination: {
      type: String,
      default: null,
    },
    // for other than hospital
    role: {
      type: String,
      default: null,
    },
    // current hospital
    currentHospital: {
      type: String,
      default: null,
    },
    currentRole: {
      type: String,
      default: null,
    },
    hospitalData: {
      type: Object,
      default: null,
    },
    errors: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    isEdit() {
      return this.$route.path.includes("edit");
    },
  },
  watch: {
    formEdit: {
      // populate to be edited values
      handler(val) {
        if (this.isEdit) {
          this.formdata = { ...val };
        }
      },
    },
    currentHospital: {
      handler(val) {
        // set hospital id by current user login
        // only if user is not admin
        if (this.role !== "ADMIN") {
          this.formdata["hospitalId"] = val;
        }
      },
      immediate: true,
    },
    formLoading: {
      handler(val) {
        this.loadingState = val;
      },
    },
    hospitalData: {
      handler(val) {
        if (val) {
          this.updateFieldValue({
            value: val.postcode,
            field: "postcode",
          });
          this.updateFieldValue({
            value: val.city,
            field: "city",
          });

          this.updateComponent++;
        }
      },
      deep: true,
    },
  },
  mounted() {
    if (!this.isEdit) {
      // construct formdata
      this.formdata["@class"] = this.javaClass;
      this.form.forEach((el) => {
        this.formdata[el.name] = null;
      });

      // set current role
      this.formdata["role"] = this.role;
    }
  },
  data: () => ({
    formdata: {},
    loadingState: false,
    updateComponent: 1,
  }),
  methods: {
    updateFieldValue(params) {
      const { value, field } = params;
      this.formdata[field] = value;

      if (
        field === "hospitalId" &&
        !this.$route.path.includes("hospital")
      ) {
        let hospitalId = this.currentRole === "ADMIN" ? value : this.currentHospital;
        this.$emit("action:get-hospital-data", hospitalId);
      }
    },
    cancelDialog() {
      Swal.fire({
        title: "Are you sure ?",
        text: "Your changes will be lost",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.push(this.backButtonDestination);
        }
      });
    },
  },
};
</script>
